.cs-message--incoming .cs-message__content {
  background-color: #D6EDFE;
}

.cs-message--outgoing .cs-message__content {
  background-color: #7CC7FC;
  padding-left: 8px;
}

.cs-message.cs-message--outgoing.cs-message--single.msg-file div,
.cs-message.cs-message--outgoing.cs-message--single.msg-file {
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.cs-message.cs-message--outgoing.cs-message--single.msg-file .cs-message__content {
  background-color: #fff;
  border: 1px solid #d1dbe3;
  text-align: right;
  font-family: Titillium Web;
  font-size: 1rem;
}

.cs-message__custom-content {
  padding: 0.9rem 0.5rem;
}

.cs-message.cs-message--outgoing.cs-message--single .cs-message__content {
  border-radius: 0.5rem 0.5rem 0 0.5rem;
}

.cs-message.cs-message--incoming.cs-message--single .cs-message__content {
  border-radius: 0.5rem 0.5rem 0.5rem 0;
}

.cs-message__content {
  padding: 0;
}

.cs-message.cs-message--incoming.cs-message--single.msg-error div,
.cs-message.cs-message--incoming.cs-message--single.msg-error {
  border-radius: 0 0.5rem 0.5rem 0;
}

.cs-message.cs-message--incoming.cs-message--single.msg-error .cs-message__content {
  background-color: #EFCCD2;
}

.cs-message-input__content-editor-wrapper,
.scrollbar-container.cs-message-input__content-editor-container,
.cs-message-input__content-editor,
.cs-message-input--disabled .cs-message-input__content-editor-wrapper {
  background-color: #D6EDFE;
}

.cs-message-input__content-editor[data-placeholder]:empty:before,
.cs-message-input__content-editor {
  color: #616161;
  white-space: pre-wrap;
}

.cs-button--send {
  color: #008ac9;
  
  &:disabled{
    color: #d6edfe;
  }
}

.cs-message-input {
  max-height: 80%;
  order: 2;
}

.cs-message-input__content-editor-wrapper {
  max-height: 100%;
}

.scrollbar-container.cs-message-input__content-editor-container {
  max-height: none;
}

/* Queste regole sono necessarie per compatibilità su Safari */
#chatgpt-logo {
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
}
