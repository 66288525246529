body {
  font-family: "Titillium Web";
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;
  -webkit-overflow-scrolling: touch;
}

#root {
  position: fixed;
  width: 100%;
  height: 100%;
}

* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

ul li::before {
  content: "\2022";
  color: #008AC9;
  display: inline-block;
  width: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

ul li {
  color: #616161;
  font-weight: 600;
  font-size: 14px;
}

ul li p {
  display: inline;
}
